import TipCard from '@ui-modules/tipJar/components/TipCard';
import type { TProfile, TTip, TTipMatch, TTipWithDetails, TTipContext } from '@typings';
import { useCallback, useEffect } from 'react';
import { useBlockedAndBlockingUsers, useNavigate, useTranslation } from '@hooks';
import type { Components, ItemContent, VirtuosoProps } from 'react-virtuoso';
import { Virtuoso } from 'react-virtuoso';
import { Gap } from '@ui-kit';
import { TipEntity } from '@ui-modules/tipJar/utils/TipEntity';
import { ROUTES } from '@constants';
import ActivityIndicator from '@ui-kit/components/ActivityIndicator';
import styles from './TipList.module.css';
import EmptyTipList from '../EmptyTipList';

const TipList = ({
	data: tips = [],
	highlightedText,
	isFetchingNextPage,
	isLoading,
	tipContext,
	hideEmptyState = false,
	HeaderComponent,
	...virtuosoProps
}: ITipListProps) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { blockedAndBlockingUsers } = useBlockedAndBlockingUsers();
	const openProfile = (profileId: TProfile['id']) => navigate(ROUTES.memberProfile(profileId));
	useEffect(() => {
		const timeoutId = setTimeout(() => {
			const element = document.querySelector('[data-viewport-type="element"]') as HTMLElement;
			if (element) {
				element.style.width = '100vw';
			}
		}, 0);

		return () => clearTimeout(timeoutId); // // fix issue with correct size of virtuoso container for windows T21C-8142  use setTimeout, first render dosent apply styles.
	}, []);
	const renderTipItem = useCallback<ItemContent<TTip | TTipMatch | TTipWithDetails, unknown>>(
		(_, tip) => {
			if (
				!tip ||
				(TipEntity.isTipWithDetails(tip) &&
					blockedAndBlockingUsers.some((blockedUser) => blockedUser.id === tip.owner?.id))
			) {
				return <Gap gap={1} />;
			}

			return (
				<TipCard
					className={styles.tipCard}
					highlightedText={highlightedText}
					key={tip.id}
					tip={tip}
					onClick={() => navigate(ROUTES.tipPage(tip.id, tipContext))}
					onProfileClick={
						TipEntity.isTipWithDetails(tip) && tip.details.review
							? () => tip.details.review && openProfile(tip.details.review.owner.profileID)
							: undefined
					}
				/>
			);
		},
		[blockedAndBlockingUsers, highlightedText],
	);

	return (
		<>
			{!hideEmptyState && !isLoading && !tips.length ? (
				<EmptyTipList
					message={t("Sorry, we couldn't find any results for the category you selected. Please alter your input.")}
				/>
			) : null}
			{tips.length ? (
				<Virtuoso
					className={styles.tipList}
					defaultItemHeight={137}
					{...virtuosoProps}
					components={{
						Header: HeaderComponent,
						Footer: () => (isFetchingNextPage ? <ActivityIndicator size={'small'} type="fit" /> : <Gap gap={8} />),
					}}
					data={tips}
					itemContent={renderTipItem}
					totalCount={tips?.length}
				/>
			) : null}
		</>
	);
};

export interface ITipListProps
	extends Omit<VirtuosoProps<TTip | TTipMatch, unknown>, 'components' | 'totalCount' | 'itemContent'> {
	tipContext?: TTipContext;
	isFetched?: boolean;
	isLoading: boolean;
	isFetchingNextPage: boolean;
	highlightedText?: string;
	hideEmptyState?: boolean;
	HeaderComponent?: Components<TTip | TTipMatch, unknown>['Header'];
}

export default TipList;
