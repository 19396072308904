import { useService, useQuery } from '@hooks';
import { dayjs } from '@utils';
import type { TGroupMeetingsForTipCreation } from '@typings';

export const useGroupMeetingsForTipCreationQuery = (
	defaultMeetingId?: string | null,
	tipReviewOwnerId?: string,
	tipReviewIdOrNew?: string,
) => {
	const api = useService('ApiService');
	return useQuery<TGroupMeetingsForTipCreation[], Error>(
		['events.groupMeetingsForTipCreation', tipReviewOwnerId],
		async () => {
			const response = await api.tips.getGroupMeetingsForTipCreation(tipReviewOwnerId);
			if (defaultMeetingId && !response.find((meeting) => meeting.id === defaultMeetingId)) {
				const defaultMeeting = await api.meeting.getMeeting(defaultMeetingId);

				if (defaultMeeting.groups) {
					const groupMeetingsForTipCreation: TGroupMeetingsForTipCreation = {
						id: defaultMeeting.id,
						title: defaultMeeting.title,
						userAttendanceCommunityShortName: defaultMeeting.groups.map((group) => group.community.shortName),
						startDate: defaultMeeting.startDate,
					};
					return [groupMeetingsForTipCreation, ...response];
				}
			}
			return response;
		},
		{
			select: (data) => data.sort((a, b) => new Date(a.startDate).getTime() - new Date(b.startDate).getTime()),
			staleTime: dayjs.duration(10, 'minutes').asMilliseconds(),
			enabled: tipReviewIdOrNew === 'new' || !!tipReviewOwnerId,
		},
	);
};
