import { useService } from '@services';
import { useNavigate, useNotification, useQuery, useTranslation } from '@hooks';
import { dayjs } from '@utils';
import type { TDeal } from '@typings';
import type { AxiosError } from 'axios';
import type { UseQueryOptions } from '@tanstack/react-query';

export const useGetDealQuery = (
	dealId: TDeal['id'] | undefined,
	options?: UseQueryOptions<TDeal, AxiosError> & { readonly?: boolean },
) => {
	const api = useService('ApiService');
	const reactQuery = useService('ReactQueryService');
	const { showInfo } = useNotification();
	const { t } = useTranslation();
	const navigate = useNavigate();

	return useQuery<TDeal, AxiosError>(
		reactQuery.queryKeys.getDeal(dealId),
		async () => await api.invest.getDealById(dealId as string),
		{
			enabled: !!dealId,
			...options,
			onError(error) {
				reactQuery.refreshWaiverIfDealsForbiddenError(error);
				if (!options?.readonly)
					reactQuery.onNoFoundResponseError(error, () => {
						navigate(-1);
						showInfo({ title: t("The deal you're trying to open is no longer available") });
					});
			},
			refetchOnMount: 'always',
			staleTime: dayjs.duration(15, 'minutes').asMilliseconds(),
		},
	);
};
