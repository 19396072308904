import type {
	IInternalShare,
	TActivity,
	TEvent,
	TNetworkConnection,
	TShareIntentInternalShareType,
	TCommunity,
	TTip,
} from '@typings';
import type { TDeal, TUser } from '@tiger21-llc/connect-shared/src/typings';
import { formatEventVenue } from './index';
import { adaptEventRecord } from '@ui-modules/events/components/EventItem/adaptEventReccord';

export class InternalShareEntity {
	/**
	 * Generates a string based on the given internal share object.
	 *
	 * @param internalShare - An object of type `{type: string; id: string}` containing the following properties:
	 * @returns A string representing the string for the internal share which can be stored in the DB.
	 * @example
	 * ```typescript
	 * const internalShare: IInternalShare = { type: 'event', id: '123' };
	 * const sharedContent = internalShareToShareableContent(internalShare);
	 * console.log(sharedContent); // Outputs: "events/123"
	 * ```
	 */
	public internalShareToShareableContent = (internalShare: IInternalShare): string => {
		return `${internalShare.type}/${internalShare.id}`;
	};

	/**
	 * Extracts the type and id from a shared content string.
	 *
	 * @param sharedContent - A string representing the shared content in the format `type/id`,
	 * or `null`/`undefined` if the input is invalid or not provided.
	 * @returns An object of type `{type: string; id: string}` containing the extracted type and id,
	 * or `null` if the input is invalid.
	 * @example
	 * ```typescript
	 * const sharedContent = "event/123";
	 * const result = internalSharedContentOrienteering(sharedContent);
	 * console.log(result); // Outputs: { type: 'event', id: '123' }
	 *
	 * const invalidContent = null;
	 * const result2 = internalSharedContentOrienteering(invalidContent);
	 * console.log(result2); // Outputs: null
	 * ```
	 */
	public internalSharedContentOrienteering = (
		sharedContent: string | null | undefined,
	): { type: string; id: string } | null => {
		if (typeof sharedContent !== 'string') return null;
		const [type, id] = sharedContent.split('/');
		return { type, id };
	};

	/**
	 * Generates a deep link string based on the given shareable content string.
	 *
	 * @param shareableContent - A string in the format `type/id` (e.g., `event/123`).
	 * @returns A string representing the deep link URL for the internal share.
	 * @example
	 * ```typescript
	 * const shareableContent = "event/123";
	 * const deepLink = internalShareToDeepLink(shareableContent);
	 * console.log(deepLink); // Outputs: "calendar/events/123"
	 * ```
	 */
	public internalShareToRoute = (shareableContent: string): string => {
		const [type, id] = shareableContent.split('/');

		const routeMappingFunction = this.typeShareToRouteMap[type as TShareIntentInternalShareType];

		return routeMappingFunction(id);
	};

	/**
	 * A mapping of internal share types to route generation functions.
	 */
	protected typeShareToRouteMap: Record<TShareIntentInternalShareType, (id: string) => string> = {
		discussion: (discussionId: string) => `/posts/${discussionId}`,
		network: (networkId: string) => `/networks/${networkId}`,
		deal_post: (dealPostId: string) => `/deal/${dealPostId}`,
		deal: (dealId: string) => `/networks/${dealId}`,
		network_connection: (networkConnectionId: string) => `/networks/${networkConnectionId}/connections`,
		network_connection_post: (networkConnectionPostId: string) =>
			`/network-connections/${networkConnectionPostId}/details`,
		chapter: (chapterId: string) => `/groups/chapters/${chapterId}`,
		group: (groupId: string) => `/groups/${groupId}`,
		meeting: (meetingId: string) => `/calendar/group_meetings/${meetingId}`,
		event: (eventId: string) => `/calendar/events/${eventId}`,
		tip: (tipId: string) => `/tips/tip/${tipId}`,
	};

	/**
	 * Extracts shared content data into a structured object.
	 *
	 * @param params - Objects containing event, meeting, deal, etc., data from queries.
	 * @param orientedShare - The oriented share object with `type` and `id`.
	 * @returns A structured object containing shared content data.
	 */
	public constructSharedContentData = (
		params: {
			event?: TEvent | null;
			meeting?: TEvent;
			deal?: TDeal;
			networkConnection?: TNetworkConnection;
			community?: TCommunity | null;
			activity?: TActivity;
			tip?: TTip;
		},
		orientedShare: { type?: string; id?: string } | null | undefined,
	): IInternalShare => {
		const { event: initialEvent, meeting: initialMeeting, ...restParams } = params;
		const adaptedEvent = initialEvent ? adaptEventRecord(initialEvent) : null;
		const adaptedMeeting = initialMeeting ? adaptEventRecord(initialMeeting) : null;

		return {
			type: this.getShareType(orientedShare),
			id: this.getContentId({ adaptedEvent, adaptedMeeting, ...restParams }),
			source: this.buildContentSource(params, orientedShare, adaptedEvent, adaptedMeeting),
			title: this.getContentTitle({ adaptedEvent, adaptedMeeting, ...restParams }),
			subtitle: this.getEventVenue(adaptedEvent, adaptedMeeting),
			userId: this.getUserId(restParams),
			image: this.getContentImage(restParams),
			compactEvent: this.createCompactEvent(adaptedEvent ?? adaptedMeeting),
		};
	};

	// Helper methods
	private getShareType = (orientedShare: { type?: string } | null | undefined): TShareIntentInternalShareType => {
		return orientedShare?.type as TShareIntentInternalShareType;
	};

	private getContentId = (params: {
		adaptedEvent?: TEvent | null;
		adaptedMeeting?: TEvent | null;
		deal?: TDeal;
		networkConnection?: TNetworkConnection;
		community?: TCommunity | null;
		activity?: TActivity;
		tip?: TTip;
	}): string => {
		const { adaptedEvent, adaptedMeeting, deal, networkConnection, community, activity, tip } = params;
		return (adaptedEvent?.id ??
			adaptedMeeting?.id ??
			deal?.id ??
			networkConnection?.id ??
			community?.id ??
			activity?.id ??
			tip?.id ??
			'') as string;
	};

	private buildContentSource = (
		params: {
			community?: TCommunity | null;
			activity?: TActivity;
			networkConnection?: TNetworkConnection;
			tip?: TTip;
			deal?: TDeal;
		},
		orientedShare: { type?: string } | null | undefined,
		adaptedEvent?: TEvent | null,
		adaptedMeeting?: TEvent | null,
	): string => {
		const source = [
			{ condition: params.activity, value: 'discussion' },
			{ condition: orientedShare?.type === 'network_connection_post', value: 'charity' },
			{ condition: params.community?.definition, value: params.community?.definition },
			{ condition: adaptedEvent, value: adaptedEvent?.['@type'] },
			{ condition: adaptedMeeting, value: adaptedMeeting?.['@type'] },
			{ condition: params.networkConnection, value: params.networkConnection?.community?.definition },
			{ condition: params.tip, value: 'tip' },
			{ condition: params.deal, value: 'deal' },
		].find(({ condition }) => condition)?.value;

		const origin = this.getContentOrigin(params, orientedShare);
		// eslint-disable-next-line sonarjs/no-nested-template-literals
		return source ? `${source}${origin ? ` • ${origin}` : ''}` : '';
	};

	private getContentOrigin = (
		params: {
			activity?: TActivity;
			networkConnection?: TNetworkConnection;
			community?: TCommunity | null;
		},
		orientedShare: { type?: string } | null | undefined,
	): string | undefined => {
		if (orientedShare?.type === 'network_connection_post') return undefined;
		return (
			params.activity?.collectionCommunityReference?.data?.name ??
			params.networkConnection?.community?.name ??
			params.community?.tab
		);
	};

	private getContentTitle = (params: {
		adaptedEvent?: TEvent | null;
		adaptedMeeting?: TEvent | null;
		deal?: TDeal;
		networkConnection?: TNetworkConnection;
		community?: TCommunity | null;
		activity?: TActivity;
		tip?: TTip;
	}): string => {
		return (
			params.adaptedEvent?.title ??
			params.adaptedMeeting?.title ??
			params.deal?.name ??
			params.networkConnection?.title ??
			params.community?.name ??
			params.activity?.subject ??
			params.activity?.body ??
			params.tip?.title ??
			''
		);
	};

	private getEventVenue = (...events: Array<TEvent | null | undefined>): string => {
		const validEvent = events.find(Boolean);
		return validEvent ? formatEventVenue(validEvent).join(', ') : '';
	};

	private getUserId = (params: {
		deal?: TDeal;
		networkConnection?: TNetworkConnection;
		activity?: TActivity;
	}): string | undefined => {
		return params.deal?.creator?.id ?? params.networkConnection?.owner?.id ?? params.activity?.actor?.id;
	};

	private getContentImage = (params: { activity?: TActivity; tip?: TTip }): string => {
		return (
			params.activity?.actor?.data?.avatar ?? params.tip?.coverUrl ?? params.tip?.galleryFiles?.[0]?.documentUrl ?? ''
		);
	};

	private createCompactEvent = (
		event?: TEvent | null,
	): Pick<TEvent, '@type' | 'startDate' | 'endDate' | 'networks' | 'groups'> | undefined => {
		if (!event) return undefined;

		return {
			'@type': event['@type'],
			startDate: event.startDate,
			endDate: event.endDate,
			networks: event.networks,
			groups: event.groups,
		};
	};

	public isTipRestricted = (sharedContent: string, user: TUser) => {
		return sharedContent.includes('tip') && !user.features?.tips;
	};

	public isDealRestricted = (sharedContent: string, user: TUser) => {
		return sharedContent.includes('deal') && !user.features?.invest?.owner;
	};

	public isDiscussionHidden = (community?: TCommunity) => {
		return community?.communityType.visibility == 'hidden' || community?.communityType.visibility === 'private';
	};
}
