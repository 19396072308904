import type { Channel, MessageResponse } from 'stream-chat';
import { Badge, MuteGroupIcon } from '@ui-kit';
import styles from './ChannelPreview.module.css';
import { useMe, useTranslation, useChat } from '@hooks';
import { clsx, toString } from '@utils';
import { formatLastMessagePreviewDate } from '@ui-modules/chat/utils/formatLastMessagePreviewDate';
import ChannelPreviewAvatar from '../ChannelPreviewAvatar';
import ChannelPreviewTitle from '../ChannelPreviewTitle';

import { useDraftAPI } from '@ui-modules/chat/hooks/useDraftAPI';
import { useMemo } from 'react';
import { getChatMessageAttachedContentIcon, getChatMessageAttachedContents } from './getChatMessageAttachmentTypes';

const ChannelPreview = ({ currentChannel, lastMessage, unread = 0, onClick }: IChannelPreviewProps) => {
	const { user } = useMe();
	const { selectedChannel } = useChat();
	const { t } = useTranslation();
	const { draftMessage } = useDraftAPI(currentChannel?.cid);
	const inputDraft = toString(draftMessage?.text);
	const inputAttachmentsDraft = draftMessage?.attachments ?? [];
	const inputEventsDraft = draftMessage?.selectedEvent;

	const lastMessageReportedId = ((currentChannel?.data?.lastReportedMessageId as TLastReportedMessage) || {})[user?.id];
	const attachedContents = getChatMessageAttachedContents(lastMessage);

	const latestMessage = useMemo(() => {
		return lastMessage?.text ? lastMessage.text : '';
	}, [lastMessage?.text]);

	const lastMessageAttachments = () => {
		return (
			<div>
				{attachedContents?.length ? (
					<div className={styles.channelPreviewItem__attachmentIconsBox}>
						{attachedContents.map((attach, index) => {
							const Icon = getChatMessageAttachedContentIcon(attach);
							if (!Icon) return null;
							return <Icon fill="#858585" height={16} key={`chat-preview-message-icon-${index}`} width={16} />;
						})}
					</div>
				) : (
					<div className={styles.channelPreviewItem__lastMessage}>{latestMessage}</div>
				)}
			</div>
		);
	};
	return (
		<div
			className={clsx(
				currentChannel.id === selectedChannel?.id
					? styles.channelPreviewItem__container_selected
					: styles.channelPreviewItem__container,
			)}
			onClick={() => {
				onClick(currentChannel);
			}}
			onKeyDown={() => {
				onClick(currentChannel);
			}}
		>
			<ChannelPreviewAvatar channel={currentChannel} />
			<div className={styles.channelPreviewItem__nameAndLastMessageContainer}>
				<ChannelPreviewTitle channel={currentChannel} />
				{inputDraft?.length || inputAttachmentsDraft?.length || inputEventsDraft ? (
					<div className={styles.channelPreviewItem__draftMessage}>{t('Draft')}</div>
				) : lastMessage?.type === 'deleted' ? (
					<div className={styles.channelPreviewItem__lastMessage}>{t('This message was deleted...')}</div>
				) : (
					<>
						{lastMessageReportedId && lastMessageReportedId === lastMessage?.id ? (
							<div className={styles.channelPreviewItem__lastMessage}>{t('Message reported')}</div>
						) : (
							lastMessageAttachments()
						)}
					</>
				)}
			</div>
			<div className={styles.channelPreviewItem_dateContainer}>
				<div className={styles.channelPreviewItem__badgeAndMuteIconContainer}>
					<div>
						<Badge position="inline" value={unread} />
					</div>
					{currentChannel.muteStatus().muted ? (
						<div className={styles.channelPreviewItem__badgeContainer}>
							<MuteGroupIcon height={18} width={18} />
						</div>
					) : null}
				</div>
				<div
					className={
						currentChannel.muteStatus().muted || currentChannel.state.unreadCount > 0
							? styles.channelPreviewItem__dateWithbadge
							: styles.channelPreviewItem__date
					}
				>
					{lastMessage?.created_at ? formatLastMessagePreviewDate(t)(`${lastMessage?.created_at}`) : ''}
				</div>
			</div>
		</div>
	);
};

export default ChannelPreview;

export interface IChannelPreviewProps {
	currentChannel: Channel;
	lastMessage: MessageResponse | undefined;
	unread: number | undefined;
	onClick: (currentChannel: Channel) => void;
}

type TLastReportedMessage = { [key: string]: string };
