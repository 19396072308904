import { FormPageLayout, Page } from '../../components';
import { ActivityIndicator, EmptyStateMessage, Gap } from '@ui-kit';
import TipReviewForm from '@ui-modules/tipJar/components/TipReviewForm';
import { useTranslation, useNavigate, useParams, useLayoutEffect, useNotification, useMe } from '@hooks';
import { useEditTipReviewMutation } from '@ui-modules/tipJar/hooks/useEditTipReviewMutation';
import { useSendTipReviewMutation } from '@ui-modules/tipJar/hooks/useSendTipReviewMutation';
import { ROUTES } from '@constants';
import styles from './TipReviewPage.module.css';
import type { TUid } from '@tiger21-llc/connect-shared/src/typings/base/Uid.type';
import { useTipContextQueryParams } from '@ui-modules/tipJar/hooks/useTipContextQueryParams';
import { compact } from '@utils';
import { useMyTipReviewQuery } from '@ui-modules/tipJar/hooks/useMyTipReviewQuery';
import { useAttachedMeetingsForTipCreationQuery } from '@ui-modules/tipJar/hooks/useAttachedMeetingsForTipCreationQuery';
import { useGroupMeetingsForTipCreationQuery } from '@ui-modules/tipJar/hooks/useGroupMeetingsForTipCreationQuery';
import { useTipQuery } from '@ui-modules/tipJar/hooks/useTipQuery';
import { useTipReviewQuery } from '@ui-modules/tipJar/hooks/useTipReviewQuery';

const TipReviewPage = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const { showSuccess } = useNotification();
	const { user } = useMe();
	const isAdmin = user.roles.includes('ROLE_ADMIN');
	const { tipId, tipReviewIdOrNew } = useParams<Partial<ITipReviewPageParams>>() as ITipReviewPageParams;
	const { contextCalendarItemId } = useTipContextQueryParams();
	const tipReviewId = tipReviewIdOrNew === 'new' ? undefined : tipReviewIdOrNew;
	const myTipReviewQuery = useMyTipReviewQuery(tipId as string);
	const tipReviewQuery = useTipReviewQuery(tipId as string, tipReviewId);
	const pageTitle = tipReviewId ? t('Edit Review') : t('Add Review');
	const editing = !!myTipReviewQuery.data;
	useLayoutEffect(() => {
		if (!tipId) navigate(-1);
	}, [tipId]);

	const tipQuery = useTipQuery(tipId);
	const { data: groupMeetingsForTipCreation = [], isFetching: isFetchingMeetings } =
		useGroupMeetingsForTipCreationQuery(
			contextCalendarItemId,
			tipReviewIdOrNew === 'new' && isAdmin ? user.id : tipReviewQuery.data?.owner.id,
			!isAdmin ? tipReviewIdOrNew : undefined,
		);

	const { mutateAsync: editTipReview } = useEditTipReviewMutation({
		onSuccess: () => {
			navigate(-1);
		},
	});

	const { data: attachedMeetingsForTipCreation = [], isFetching: isFetchingAttachedMeetings } =
		useAttachedMeetingsForTipCreationQuery(tipReviewId!, {
			select: (data) => compact(data.map((meeting) => meeting.meetingId)),
		});
	const sendTipReviewMutation = useSendTipReviewMutation({
		onSuccess: () => {
			showSuccess({ title: t('Tip Saved') });
			navigate(-1);
		},
	});

	return (
		<Page title={pageTitle}>
			{tipQuery.isLoading ||
			myTipReviewQuery.isFetching ||
			isFetchingAttachedMeetings ||
			isFetchingMeetings ||
			tipReviewQuery.isFetching ? (
				<ActivityIndicator type="fit" />
			) : tipQuery.isError || myTipReviewQuery.isError ? (
				<EmptyStateMessage text={t('Something went wrong')} />
			) : (
				<TipReviewForm
					editing={editing}
					initialValues={{
						...(isAdmin ? tipReviewQuery.data : myTipReviewQuery.data),
						calendarItemsId: compact([contextCalendarItemId, ...attachedMeetingsForTipCreation]),
					}}
					renderWrapper={({ formProps, children }) => (
						<FormPageLayout
							allowedNextPaths={[ROUTES.createTipReview()]}
							formProps={formProps}
							headerTitle={pageTitle}
							isSaveButtonHidden
							scrollEnabled
						>
							<div className={styles.tipReviewPage}>
								{children}
								<Gap gap={24} />
							</div>
						</FormPageLayout>
					)}
					submitLabel={tipReviewId ? t('Save') : t('Submit')}
					targetMeetings={groupMeetingsForTipCreation}
					title={tipReviewId ? t('Review Details') : t('Review “{{tipName}}”', { tipName: tipQuery.data?.title })}
					onSubmit={async (formValues) => {
						if (tipId && tipReviewId) {
							await editTipReview({ tipId, tipReviewId, ...formValues });
						} else {
							await sendTipReviewMutation.mutateAsync({ ...formValues, tipId });
						}
					}}
				/>
			)}
		</Page>
	);
};

export interface ITipReviewPageParams {
	tipId: TUid;
	tipReviewIdOrNew: TUid | 'new';
}

export default TipReviewPage;
