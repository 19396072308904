import {
	useMessageContext,
	ReactionsList,
	ReactionSelector,
	messageHasReactions,
	Attachment,
	useChannelActionContext,
} from 'stream-chat-react';
import styles from '../OtherMessage/OtherMessage.module.css';
import { clsx, InternalShareEntity, isOnlyEmoji } from '@utils';
import { useMemo, useNavigate, useTranslation } from '@hooks';
import { customReactionEmojis } from '../Message/customReactionsEmojiData';
import MessageAvatar from '@ui-modules/chat/components/MessageAvatar';
import ChatAttachment from '@ui-modules/chat/components/ChatAttachment';
import ChatEventMessage from '@ui-modules/chat/components/ChatEventMessage';
import { MessageOptions } from '@ui-modules/chat/components/Message/MessageOptions';
import ChatMessageText from '@ui-modules/chat/components/ChatMessageText';
import QuotedMessage from '@ui-modules/chat/components/QuotedMessage';
import type { MessageResponse } from 'stream-chat';
import { When } from '@ui-kit';
import { messageHasGiphyAttachment } from '../Message/Message.utils';
import ChatLinksPreview from '../ChatLinksPreview';
import type { FC, PropsWithChildren } from 'react';
import ShareableCard from '@ui-modules/share/components/ShareableCard';

const MessageInner = ({ quotedMessage, chatLogicIsBlocked }: IMessageParams) => {
	const { isReactionEnabled, message, reactionSelectorRef, showDetailedReactions, handleAction } = useMessageContext();

	const messageIsEmojiOnly = isOnlyEmoji(message);
	const internalShareEntity = new InternalShareEntity();
	const navigate = useNavigate();
	const hasReactions = messageHasReactions(message);
	const { t } = useTranslation();

	const { jumpToMessage } = useChannelActionContext();

	const eventId = message?.eventId as string | undefined;
	const messageHasText = !!message.text?.trim?.();
	const mentionedUsers = useMemo(() => message.mentioned_users?.map((el) => `@${el.name}`), [message]);

	if (message.type === 'deleted') {
		return (
			<div className={styles.otherMessage__deleteMessageAvatarContainer}>
				<div className={styles.otherMessage__deleteMessageAvatar}>
					<MessageAvatar />
				</div>
				<div className="str-chat__message-bubble" key={message.id}>
					<div className={styles.otherMessage__deleteMessageContainer}>
						<div className={styles.otherMessage__textMessage}>{t('This message was deleted...')}</div>{' '}
					</div>
				</div>
			</div>
		);
	}
	return (
		<div className={clsx('str-chat__message-inner')} data-testid="message-inner">
			<MessageOptions shouldHideReactions={chatLogicIsBlocked} />
			<div className="str-chat__message-reactions-host">
				{hasReactions && isReactionEnabled && <ReactionsList reactionOptions={customReactionEmojis} reverse />}
				{showDetailedReactions && isReactionEnabled && (
					<ReactionSelector reactionOptions={customReactionEmojis} ref={reactionSelectorRef} />
				)}
			</div>
			<div
				className={
					!messageIsEmojiOnly && (message.text?.trim() !== '' || quotedMessage?.id) ? 'str-chat__message-bubble' : ''
				}
			>
				<div
					className={clsx(
						message.text?.trim() !== '' || quotedMessage?.id || !messageIsEmojiOnly
							? styles.otherMessage__textContainer
							: styles.otherMessage__textContainer_withoutBg,
					)}
				>
					<MessageWrapper clickable={!!quotedMessage} onClick={() => quotedMessage && jumpToMessage(quotedMessage.id)}>
						{quotedMessage && quotedMessage.id ? <QuotedMessage quotedMessage={quotedMessage} /> : null}
						<ChatLinksPreview text={message.text} />
						{message?.attachments && messageHasGiphyAttachment(message as MessageResponse) && (
							<Attachment actionHandler={handleAction} attachments={message.attachments} />
						)}
						{message?.attachments && !messageHasGiphyAttachment(message as MessageResponse) && (
							<ChatAttachment attachments={message.attachments} text={message.text} />
						)}
						<> {eventId && !message.deleted_at && <ChatEventMessage eventId={eventId} />}</>
						{!!(message?.sharedContent && !message.deleted_at) && (
							<ShareableCard
								containerClassName={styles.otherMessage__sharedContentContainer}
								sharedContent={message?.sharedContent as string}
								onClick={() => {
									navigate(internalShareEntity.internalShareToRoute(message?.sharedContent as string), {});
								}}
							/>
						)}
						<When condition={messageHasText}>
							<ChatMessageText
								className={clsx(
									!messageIsEmojiOnly ? styles.otherMessage__textMessage : styles.otherMessage__emojiMessage,
								)}
								mentions={mentionedUsers}
								text={message.text}
							/>
						</When>
					</MessageWrapper>
				</div>
			</div>
		</div>
	);
};

const MessageWrapper: FC<PropsWithChildren<{ clickable: boolean; onClick: () => void }>> = ({
	children,
	clickable,
	onClick,
}) => {
	if (clickable) {
		return (
			<div onClick={() => onClick()} onKeyDown={() => onClick()}>
				{children}
			</div>
		);
	}
	return <>{children}</>;
};

export default MessageInner;
export interface IMessageParams {
	quotedMessage: MessageResponse | undefined;
	chatLogicIsBlocked: boolean | null;
}
