import type { IFeedListenerParams, IFeedService } from '../interfaces/FeedService.interface';
import * as stream from 'getstream';
import type { ReactionFilterConditions, StreamClient } from 'getstream';
import type { IAppConfig } from '../interfaces/AppConfig.interface';
import type { IDevLogger } from '../interfaces/DevLogger.interface';
import type { IBugTracker } from '../interfaces/BugTracker.interface';
import type {
	TActivity,
	TActivitySettings,
	TUser,
	TAttachments,
	TLatestActivity,
	TData,
	TPostEditParams,
	TComment,
	TFeedReportData,
	TPostActionParams,
} from '@typings';
import type { IAxiosService } from '../interfaces/AxiosService.interface';
import type { ApiVideoService } from './ApiVideoService';
import { compact, extractFileExtensionFromUrl, lookupMimeType } from '@utils';

export class StreamFeedService implements IFeedService {
	static inject = ['AppConfigService', 'logger', 'SentryService', 'AxiosService', 'ApiVideoService'] as const;
	constructor(
		private readonly appConfig: IAppConfig,
		logger: IDevLogger,
		private readonly sentry: IBugTracker,
		private readonly axios: IAxiosService,
		private readonly apiVideo: ApiVideoService,
	) {
		this.logger = logger.child('StreamFeedService');
	}

	private logger: IDevLogger;
	#streamClient: StreamClient | undefined;
	#token: string | undefined;

	readonly notificationFeedGroup = 'notification';

	async connect(token: string): Promise<void> {
		this.logger.log('initialize StreamFeeds with token', token);
		this.#token = token;

		this.#streamClient = stream.connect(
			this.appConfig.STREAM_API_KEY,
			String(this.#token),
			this.appConfig.STREAM_APP_ID,
		);
	}

	getFeedConnectionSettings(): { apiKey: string; appId: string; token: string } {
		const apiKey = this.appConfig.STREAM_API_KEY;
		const appId = this.appConfig.STREAM_APP_ID;
		const token = this.#token as string;

		return { apiKey, appId, token };
	}

	handleFeedError = (error: Error | unknown) => {
		this.logger.error(error);
	};

	async markAllNotificationsAsRead(userSlug: TUser['slug']) {
		if (!this.#streamClient) throw new Error('No StreamClient found');
		const notificationFeed = await this.getNotificationFeed(userSlug);
		await notificationFeed.get({ mark_read: true });
	}

	private async getNotificationFeed(userSlug: TUser['slug']) {
		if (!this.#streamClient) throw new Error('No StreamClient found');

		return await this.#streamClient.feed(this.notificationFeedGroup, userSlug);
	}

	async onSubscribe(userSlug: string, callback: (data: IFeedListenerParams) => void) {
		const feed = await this.getNotificationFeed(userSlug);
		feed.subscribe((data: unknown) => callback(data as IFeedListenerParams));
	}

	async unsubscribe(userSlug: string) {
		const feed = await this.getNotificationFeed(userSlug);
		feed.unsubscribe();
	}

	async removePinnedBanner(activityId: string) {
		try {
			await this.axios.delete(`/pinned_banners/${activityId}`);
		} catch (error) {
			this.logger.error(error);
			this.sentry.captureException(error as Error);
			throw error;
		}
	}

	async getActivity(activityId: string, userSlug: TUser['slug']): Promise<TActivity | undefined> {
		if (!this.#streamClient) throw new Error('No StreamClient found');

		const userFeed = await this.#streamClient.feed('user', userSlug);
		const getActivitiesResult = await userFeed.getActivityDetail(activityId, {
			withOwnReactions: true,
			withRecentReactions: true,
			withReactionCounts: true,
		});
		const foundActivity = getActivitiesResult.results?.[0] as never as TActivity | undefined;

		return foundActivity;
	}

	async getActivities(activityIds: string[]): Promise<TActivity[]> {
		if (!this.#streamClient) throw new Error('No StreamClient found');
		if (!activityIds.length) return [];

		const activities = await this.#streamClient.getActivities({
			ids: activityIds,
			ownReactions: true,
			withOwnReactions: true,
			withRecentReactions: true,
			withReactionCounts: true,
		});
		return (activities.results || []) as unknown as TActivity[];
	}

	async getComment(commentId: string): Promise<TComment> {
		if (!this.#streamClient) throw new Error('No StreamClient found');

		const comment = await this.#streamClient.reactions.get(commentId);

		return comment as unknown as TComment;
	}

	async deletePost(activityId: string, postType: TActivitySettings['postType']): Promise<void> {
		try {
			switch (true) {
				case postType === 'button' || postType === 'event':
					return await this.axios.delete(`/stream_activities/${activityId}`);
				case postType === 'adImage':
					return await this.axios.delete(`/stream_activity_discussion_ad_images/${activityId}`);
				case postType === 'adVideo':
					return await this.axios.delete(`/stream_activity_discussion_ad_videos/${activityId}`);
				default:
					return await this.axios.delete(`/stream_activity_discussion_posts/${activityId}`);
			}
		} catch (error) {
			this.logger.error(error);
			this.sentry.captureException(error as Error);
			throw error;
		}
	}

	/* Delete comment and reply. It's useful to call it on behalf of admin to avoid restriction of ability removing only own reactions. */
	async deleteReaction(reaction_id: string): Promise<void> {
		return await this.axios.delete(`/stream_activity_reactions/${reaction_id}`);
	}

	async editReaction(reactionId: string, data: TData) {
		await this.#streamClient?.reactions.update(reactionId, data);
	}

	async uploadVideos(videos: TAttachments[]) {
		try {
			const videoPromises = videos.map((item) => {
				return this.apiVideo.createVideo(item);
			});
			return await Promise.all(videoPromises);
		} catch (error) {
			this.logger.error(error);
			this.sentry.captureException(error as Error);
			throw error;
		}
	}

	async uploadImages(images: TAttachments[]) {
		try {
			const imagesWithUrlPromises = images.map(({ localUri, uri, url, file }) => {
				const targetUrl = localUri || uri || url;
				if (file && targetUrl) {
					const fileName = targetUrl.split('/').reverse()[0];
					const mediaType = extractFileExtensionFromUrl(targetUrl);
					const mimeType = lookupMimeType(mediaType);
					return this.#streamClient?.images.upload(file, fileName, mimeType);
				}
			});
			const imagesWithUrl = await Promise.all(imagesWithUrlPromises);
			return imagesWithUrl.map((item) => item?.file).filter((item) => item);
		} catch (error) {
			this.logger.error(error);
			this.sentry.captureException(error as Error);
			throw error;
		}
	}

	async uploadDocuments(files: TAttachments[]) {
		try {
			const documentsWithUrlPromises = files.map(({ name, mimeType, file }) => {
				const encodedName = name.replaceAll(';', '%3B'); // to be able to download files with special symbols (@see T21C-5917) [@DmitriyNikolenko]
				if (file) {
					return this.#streamClient?.files.upload(file, encodedName, mimeType as string);
				}
			});

			const documentsWithUrls = await Promise.all(documentsWithUrlPromises);
			return documentsWithUrls.map((item) => item?.file).filter((item) => item);
		} catch (error) {
			this.sentry.captureException(error as Error);
			this.logger.error(error as Error);
			throw error;
		}
	}

	protected async pickUploadingAttachments({
		images,
		videos,
		files,
	}: {
		images?: TAttachments[];
		videos?: TAttachments[];
		files?: TAttachments[];
	}) {
		const getMutatedFiles = async (
			attachments: TAttachments[],
			uploadFnc: (attachments: TAttachments[]) => Promise<(string | undefined)[] | undefined>,
		) => {
			const notUploadedFiles = attachments?.filter((item) => item.url?.startsWith('blob'));
			const uploadedFiles = attachments?.filter((item) => !item.url?.startsWith('blob'));

			const mutatedUrls = await uploadFnc(notUploadedFiles);

			return [...(mutatedUrls as string[]), ...uploadedFiles.map((item) => item.url)];
		};

		const mutatedImages = images?.length
			? getMutatedFiles(images, async (attachments) => await this.uploadImages(attachments))
			: [];
		const mutatedVideos = videos?.length
			? getMutatedFiles(videos, async (attachments) => await this.uploadVideos(attachments))
			: [];
		const mutatedFiles = files?.length
			? getMutatedFiles(files, async (attachments) => await this.uploadDocuments(attachments))
			: [];

		return {
			images: await mutatedImages,
			videos: await mutatedVideos,
			files: await mutatedFiles,
		};
	}

	async updatePost({
		networkSlug,
		body,
		subject,
		owner,
		id,
		postType,
		images,
		videos,
		files,
		sharedContent,
	}: TPostEditParams): Promise<TLatestActivity> {
		const data = await this.pickUploadingAttachments({ images, videos, files });
		const headers = {
			accept: 'application/ld+json',
		};
		let url;
		if (postType === 'event') {
			url = '/stream_activity_discussion_events';
		} else if (postType === 'button') {
			url = '/stream_activity_discussion_buttons';
		} else {
			url = '/stream_activity_discussion_posts';
		}

		return await this.axios.patch(
			`${url}/${id}`,
			{
				community: networkSlug || null,
				owner,
				isPublished: true,
				title: subject || null, // null to ensure erase a value instead of ignoring changes.
				body: body || null, // null to ensure erase a value instead of ignoring changes.
				images: data.images,
				videos: data.videos,
				files: data.files,
				sharedContent,
			},
			{
				headers,
			},
		);
	}

	async createPost({
		networkSlug: to,
		body,
		subject,
		owner,
		files = [],
		images = [],
		videos = [],
		event = null,
		postType = 'Normal',
		isPromoted = false,
		buttonText = null,
		adImageUrl = null,
		link = null,
		pinnedBannerImageUrl = null,
		globalFeedName,
		publishAt = null,
		expiredAt = null,
		isNeedSendNotification,
		uploadedApiVideo,
		adVideoUrl,
		sharedContent = [],
	}: TPostActionParams): Promise<TLatestActivity> {
		const videoUrls = compact((await this.uploadVideos(videos)).concat(uploadedApiVideo));
		const imageUrls = await this.uploadImages(images);
		const fileUrls = await this.uploadDocuments(files);
		const uploadedAdImageUrl = adImageUrl && (await this.uploadImages([adImageUrl]));
		const uploadedPinnedBannerImageUrl = pinnedBannerImageUrl && (await this.uploadImages([pinnedBannerImageUrl]));

		const postCommunityKey = to === 'GLOBAL' || to === 'LEARNING' ? 'globalFeedName' : 'community';
		const postCommunityValue = to === 'GLOBAL' ? 'GLOBAL' : to === 'LEARNING' ? globalFeedName : to;

		const normalPostTypeSetting = {
			[postCommunityKey]: postCommunityValue,
			owner,
			title: subject || null,
			body: body ? body : null,
			images: images.length ? imageUrls : [],
			files: files.length ? fileUrls : [],
			isNeedSendNotification: isNeedSendNotification !== undefined ? isNeedSendNotification : true,
			videos: videoUrls.length ? videoUrls : [],
			isPromoted: isPromoted ?? false,
			publishAt: publishAt ?? undefined,
			sharedContent,
		};

		const headers = {
			accept: 'application/ld+json',
		};

		// TODO Describe real types for data returning from `/stream_activity_discussion_XXX` endpoints instead of the piece of shit called TLatestActivity.
		switch (postType) {
			case 'Event':
				return await this.axios.post(
					`/stream_activity_discussion_events`,
					{
						...normalPostTypeSetting,
						event,
						customData: [],
					},
					{
						headers,
					},
				);
			case 'Button':
				return await this.axios.post(
					`/stream_activity_discussion_buttons`,
					{
						...normalPostTypeSetting,
						buttonText,
						buttonLink: link,
						customData: [],
					},
					{
						headers,
					},
				);
			case 'Ad image':
				return await this.axios.post(
					`/stream_activity_discussion_ad_images`,
					{
						...normalPostTypeSetting,
						adImageUrl: uploadedAdImageUrl && uploadedAdImageUrl[0],
						adImageLink: link,
						customData: [],
					},
					{
						headers,
					},
				);
			case 'Ad video':
				return await this.axios.post(
					`/stream_activity_discussion_ad_videos`,
					{
						...normalPostTypeSetting,
						adVideoUrl,
						buttonText,
						buttonLink: link,
					},
					{
						headers,
					},
				);

			case 'Pinned banner':
				return await this.axios.post(
					`/pinned_banners`,
					{
						...normalPostTypeSetting,
						isPinned: true,
						pinnedBannerUrl: link,
						pinnedBannerImageUrl: uploadedPinnedBannerImageUrl && uploadedPinnedBannerImageUrl[0],
						expiredAt: expiredAt ?? undefined,
					},
					{
						headers,
					},
				);
			case 'Normal':
			default:
				return await this.axios.post(`/stream_activity_discussion_posts`, normalPostTypeSetting, {
					headers,
				});
		}
	}

	/** Custom StreamFeed doReactionsFilterRequest to ensure calling it with with_own_children which are required to work likes properly. Fixes T21C-2869. [@dmitriy.nikolenko]  */
	async doReactionsFilterRequest(filter: ReactionFilterConditions) {
		return await this.#streamClient?.reactions.filter({
			...filter,
			with_own_children: true,
		});
	}

	async addActivityReportReaction(activityId: TActivity['id'], reportData: TFeedReportData) {
		if (!this.#streamClient) throw new Error('No StreamClient found');

		await this.#streamClient?.reactions.add('report', activityId, reportData);
	}

	async addCommentReportReaction(commentId: TComment['id'], reportData: TFeedReportData) {
		if (!this.#streamClient) throw new Error('No StreamClient found');

		await this.#streamClient.reactions.addChild('report', commentId, reportData);
	}

	async deleteReportReaction(reactionId: string) {
		if (!this.#streamClient) throw new Error('No StreamClient found');

		await this.#streamClient.reactions.delete(reactionId);
	}

	async likeActivity(activityId: TActivity['id']) {
		if (!this.#streamClient) throw new Error('No StreamClient found');

		return await this.#streamClient.reactions.add('like', activityId, {});
	}

	async unlikeActivity(reactionId: string) {
		if (!this.#streamClient) throw new Error('No StreamClient found');

		return await this.#streamClient.reactions.delete(reactionId);
	}

	async getOpenGraphInfo(link: string) {
		return await this.#streamClient?.og(link);
	}
}
