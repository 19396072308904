import { clsx } from '@utils';
import styles from './ShareableCard.module.css';
import type { ReactNode } from 'react';

const RestrictedShareCard = ({ containerClassName, title, icon }: IRestrictedShareCardProps) => {
	return (
		<div className={clsx(styles.card, containerClassName)}>
			<div className={styles.gradientBackground} />
			<div className={clsx(styles.content, styles.content_small)}>
				{!!icon && <div className={styles.restrictedIconWrapper}>{icon}</div>}
				<p className={clsx(styles.information)}>{title}</p>
			</div>
		</div>
	);
};

interface IRestrictedShareCardProps {
	containerClassName?: string;
	title: string;
	icon?: ReactNode;
}

export default RestrictedShareCard;
