import { FormPageLayout, Page } from '../../components';
import { ActivityIndicator, Formik, Gap, Input, TextArea } from '@ui-kit';
import TipDetails from '@ui-modules/tipJar/components/TipDetails';
import TipDetailsImageCarousel from '@ui-modules/tipJar/components/TipDetailsImageCarousel';
import TipFormsSubmitButton from '@ui-modules/tipJar/components/TipFormsSubmitButton';
import TipImageUploadInput from '@ui-modules/tipJar/components/TipImageUploadInput';
import { useTranslation, useNavigate, useParams, useMe } from '@hooks';
import { useTipQuery } from '@ui-modules/tipJar/hooks/useTipQuery';
import { useMyTipReviewQuery } from '@ui-modules/tipJar/hooks/useMyTipReviewQuery';
import { useEditTipReviewMutation } from '@ui-modules/tipJar/hooks/useEditTipReviewMutation';
import { useEditOwnerTipMutation } from '@ui-modules/tipJar/hooks/useEditOwnerTipMutation';
import { compact } from '@utils';
import styles from './EditTipPage.module.css';
import {
	patchTipFormSchema,
	tipReviewWithSharingFormSchema,
	editTipSchema,
	type TPatchTipForm,
	type TTipReviewWithSharingForm,
	type TEditTipForm,
} from '@schemas';
import { TipReviewFormInputs } from '@ui-modules/tipJar/components/TipReviewForm/TipReviewForm';
import type { FormikProps } from 'formik';
import { ROUTES } from '../../routes';
import { useGroupMeetingsForTipCreationQuery } from '@ui-modules/tipJar/hooks/useGroupMeetingsForTipCreationQuery';
import { useAttachedMeetingsForTipCreationQuery } from '@ui-modules/tipJar/hooks/useAttachedMeetingsForTipCreationQuery';
import { useTipImageIndexState } from '@ui-modules/tipJar/hooks/useTipImageIndexState';
import type { TCarouselItem } from '@ui-modules/tipJar/components/TipsImageCarousel/TipsImageCarousel';
import { useTipReviewQuery } from '@ui-modules/tipJar/hooks/useTipReviewQuery';

const EditTipPage = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { user } = useMe();

	const tipId = useParams().tipId as string;

	const tipQuery = useTipQuery(tipId, undefined, {
		select: (tip) => ({
			...tip,
			links: [], // to hide links in edit mode
		}),
	});
	const myTipReviewQuery = useMyTipReviewQuery(tipId as string);
	const editableTipReview = user.roles.includes('ROLE_ADMIN') ? tipQuery.data?.details?.review : myTipReviewQuery?.data;
	const tipReviewId = editableTipReview?.id;
	const tipReviewQuery = useTipReviewQuery(tipId as string, tipReviewId);

	const { data: groupMeetingsForTipCreation = [], isFetching: isFetchingMeetings } =
		useGroupMeetingsForTipCreationQuery(undefined, tipReviewQuery.data?.owner.id);
	const [coverImageIndex, setCoverImageIndex, initialCoverImageIndex] = useTipImageIndexState(tipQuery.data);
	const onDisplayedGalleryItemChanged = (index: number, currentItem?: TCarouselItem) => {
		setCoverImageIndex(currentItem?.type === 'image' ? index : null);
	};

	const editOwnerTipMutation = useEditOwnerTipMutation(tipId);
	const { mutateAsync: editTipReview } = useEditTipReviewMutation({
		onSuccess: () => {
			navigate(-1);
		},
	});

	const { data: attachedMeetingsForTipCreation = [], isFetching: isFetchingAttachedMeetings } =
		useAttachedMeetingsForTipCreationQuery(tipReviewId!, {
			select: (data) => compact(data.map((meeting) => meeting.meetingId)),
		});

	return (
		<Page title={t('Tip Selected Page')}>
			{tipQuery.isInitialLoading ||
			myTipReviewQuery.isInitialLoading ||
			tipReviewQuery.isFetching ||
			isFetchingMeetings ||
			isFetchingAttachedMeetings ? (
				<ActivityIndicator size="medium" type="fit" />
			) : tipQuery.isError || myTipReviewQuery.isError ? (
				<div>{t('Tip not found')}</div>
			) : tipQuery.data ? (
				<Formik<TEditTipForm>
					initialValues={
						editTipSchema.cast(
							{
								...editableTipReview,
								tip: tipQuery.data,
								calendarItemsId: attachedMeetingsForTipCreation,
							},
							{ stripUnknown: true },
						) as TEditTipForm
					}
					isInitialValid={false}
					validateOnBlur
					validationSchema={editTipSchema}
					onSubmit={({ tip, ...tipReview }) =>
						editOwnerTipMutation
							.mutateAsync({
								coverImageIndex,
								...(patchTipFormSchema.cast(tip, { stripUnknown: true }) as TPatchTipForm),
							})
							.then(() =>
								editTipReview({
									tipId,
									tipReviewId: tipReviewId as string,
									...(tipReviewWithSharingFormSchema.cast(tipReview) as TTipReviewWithSharingForm),
								}),
							)
					}
				>
					{(formikProps) => {
						return (
							<FormPageLayout
								allowedNextPaths={[ROUTES.editTip()]}
								formProps={formikProps}
								headerTitle={t('Edit Tip')}
								isSaveButtonHidden
								scrollEnabled
							>
								<div className={styles.editTipPage}>
									<Gap gap={32} />
									<TipDetails
										Description={
											<div className={styles.editTipPage__field}>
												<h5>{t('Description')}</h5>
												<TextArea
													errorMessage={formikProps.errors.tip?.description}
													value={formikProps.values.tip.description}
													onBlur={formikProps.handleBlur('tip.description')}
													onChange={formikProps.handleChange('tip.description')}
												/>
											</div>
										}
										Gallery={
											<TipDetailsImageCarousel
												confirmImageItemRemoving={false}
												ImageUploadInput={
													<TipImageUploadInput
														disabled={formikProps.isSubmitting}
														uploading={formikProps.isSubmitting}
														onUploadFile={(file) => {
															formikProps.setFieldValue(
																'tip.galleryFiles',
																compact([
																	(formikProps.values.tip.galleryFiles ?? []).find((image) => image.isAiGenerated),
																	{
																		isAiGenerated: false,
																		documentUrl: URL.createObjectURL(file),
																		name: file.name,
																	},
																]),
															);
															formikProps.handleBlur('tip.galleryFiles');
														}}
													/>
												}
												initialDisplayedGalleryItemIndex={coverImageIndex ?? 0}
												isTipEditable
												tipId={undefined}
												tipImages={formikProps.values.tip.galleryFiles ?? []}
												onDisplayedGalleryItemChanged={onDisplayedGalleryItemChanged}
												onTipPatched={(newValues) => {
													if ('galleryFiles' in newValues) {
														formikProps.setFieldValue('tip.galleryFiles', newValues.galleryFiles);
														formikProps.handleBlur('tip.galleryFiles');
													}
												}}
											/>
										}
										initialDisplayedGalleryItemIndex={coverImageIndex ?? 0}
										isTipEditable
										tip={tipQuery.data}
										Title={
											<div className={styles.editTipPage__field}>
												<h5>{t('Title')}</h5>
												<Input
													errorMessage={formikProps.errors.tip?.title}
													value={formikProps.values.tip.title}
													onBlur={formikProps.handleBlur('tip.title')}
													onChange={formikProps.handleChange('tip.title')}
												/>
											</div>
										}
										onDisplayedGalleryItemChanged={onDisplayedGalleryItemChanged}
									/>
									<hr className={styles.separator} />
									<TipReviewFormInputs
										formikProps={formikProps as unknown as FormikProps<TTipReviewWithSharingForm>}
										secondaryFieldLabelsBold
										targetMeetings={groupMeetingsForTipCreation}
										title={tipReviewId ? t('Review Details') : t('Write a Review')}
									/>
									<TipFormsSubmitButton
										disabled={!formikProps.isValid && initialCoverImageIndex === coverImageIndex}
										loading={formikProps.isSubmitting}
										title={t('Save')}
										onClick={() => formikProps.handleSubmit()}
									/>
									<Gap gap={32} />
								</div>
							</FormPageLayout>
						);
					}}
				</Formik>
			) : null}
		</Page>
	);
};

export interface IEditTipPageParams extends Record<string, string | undefined> {
	id: string;
}

export default EditTipPage;
