import { useMe, useMemo, useNavigate, useTranslation } from '@hooks';
import { CommunityEntity, InternalShareEntity } from '@utils';
import styles from './NetworkShareModalContent.module.css';
import type { TContent } from '@ui-modules/share/components/InternalShareModal/InternalShareModal';
import { useInternalShareContext } from '@ui-modules/share/hooks/useInternalShare';
import type { TCommunity } from '@typings';
import { useMyInvestNetworksQuery } from '@ui-modules/networks/hooks/useMyInvestNetworksQuery';
import { useMyLifestyleNetworksQuery } from '@ui-modules/networks/hooks/useMyLifestyleNetworksQuery';
import { ActivityIndicator } from '@ui-kit';
import NetworkList from '@ui-modules/share/components/NetworkShareModalContent/NetworkList';
import { ROUTES } from '@constants';

const NetworkShareModalContent = ({ handleContentChange }: INetworkShareModalContentProps) => {
	const { t } = useTranslation();
	const { internalShare, hasInternalShare, resetInternalShare } = useInternalShareContext();
	const { user } = useMe();

	const internalShareEntity = new InternalShareEntity();
	const navigate = useNavigate();

	const { data: investNetworks, isLoading: isInvestNetworkLoading } = useMyInvestNetworksQuery();
	const { data: networks, isLoading: isNetworksLoading } = useMyLifestyleNetworksQuery(undefined);

	const mergedNetworks = useMemo(() => {
		if (isInvestNetworkLoading || isNetworksLoading) return [];
		return [...(investNetworks as TCommunity[]), ...(networks as TCommunity[])];
	}, [investNetworks, networks, isInvestNetworkLoading, isNetworksLoading]);

	// Networks Where User Can Have Activity
	const availableNetworks = mergedNetworks?.filter((community: TCommunity) =>
		CommunityEntity.shouldShowDiscussionsV2(community, user.roles),
	);

	const handleNetworkClick = async (network: TCommunity) => {
		if (hasInternalShare) {
			navigate(ROUTES.addPost(network.id, internalShareEntity.internalShareToShareableContent(internalShare)));
			resetInternalShare();
			handleContentChange(null);
		}
	};

	const isLoading = isNetworksLoading || isInvestNetworkLoading;

	return (
		<div>
			{isLoading ? (
				<div className={styles.networkShare__loadingWrapper}>
					<ActivityIndicator />
				</div>
			) : (
				<NetworkList
					data={availableNetworks}
					iconType="network"
					titleKey={`${t('New Discussion In')}...`}
					onClick={handleNetworkClick}
				/>
			)}
		</div>
	);
};

export interface INetworkShareModalContentProps {
	handleContentChange: (content: TContent) => void;
}

export default NetworkShareModalContent;
