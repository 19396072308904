import { InternalShareEntity } from '@utils';
import type { IInternalShare, TCommunity, TDeal } from '@typings';
import { useGetDealQuery } from '@ui-modules/deals/hooks/useGetDealQuery';
import { useGetMeetingQuery } from '@ui-modules/events/hooks/useGetMeetingQuery';
import { useGetEventQuery } from '@ui-modules/events/hooks/useGetEventQuery';
import { useNetworkConnectionQuery } from '@ui-modules/connections/hooks/useNetworkConnectionQuery';
import { useCommunityQuery } from '@ui-modules/communities/hooks/useCommunityQuery';
import { useTipQuery } from '@ui-modules/tipJar/hooks/useTipQuery';
import { useFeedActivityQuery } from '@ui-modules/feed/hooks/useFeedActivityQuery';

export const useSharedContent = (
	sharedContent: string | null | undefined,
): { data: IInternalShare; isLoading: boolean; isError: boolean; community?: TCommunity } => {
	const internalShareEntity = new InternalShareEntity();

	const orientedShare = internalShareEntity.internalSharedContentOrienteering(sharedContent);

	const {
		data: activity,
		isLoading: isActivityLoading,
		isError: isFeedActivityError,
	} = useFeedActivityQuery(orientedShare?.id, undefined, {
		enabled: orientedShare?.type === 'discussion',
		readonly: true,
	});

	const isCommunityShareType =
		orientedShare?.type === 'chapter' ||
		orientedShare?.type === 'group' ||
		orientedShare?.type === 'network' ||
		orientedShare?.type === 'deal' ||
		orientedShare?.type === 'network_connection';

	const communityId = isCommunityShareType ? orientedShare?.id : activity?.collectionCommunityReference?.id;

	const {
		data: community,
		isLoading: isCommunityLoading,
		isError: isCommunityError,
	} = useCommunityQuery(communityId, {
		enabled: !!communityId,
	});

	const {
		data: networkConnection,
		isLoading: isNetworkConnectionLoading,
		isError: isNetworkConnectionError,
	} = useNetworkConnectionQuery(orientedShare?.id as string, {
		enabled: orientedShare?.type === 'network_connection_post',
	});

	const {
		data: deal,
		isLoading: isDealLoading,
		isError: isDealError,
	} = useGetDealQuery(orientedShare?.id as TDeal['id'], {
		enabled: orientedShare?.type === 'deal_post',
		readonly: true,
	});

	const {
		data: meeting,
		isLoading: isMeetingLoading,
		isError: isMeetingError,
	} = useGetMeetingQuery(orientedShare?.id as string, undefined, {
		enabled: orientedShare?.type === 'meeting',
	});

	const {
		data: event,
		isLoading: isEventLoading,
		isError: isEventError,
	} = useGetEventQuery(orientedShare?.id, undefined, {
		enabled: orientedShare?.type === 'event' || orientedShare?.type === 'meeting',
	});

	const {
		data: tip,
		isLoading: isTipLoading,
		isError: isTipError,
	} = useTipQuery(orientedShare?.id, undefined, {
		enabled: orientedShare?.type === 'tip',
	});

	const data = internalShareEntity.constructSharedContentData(
		{ event, meeting, deal, networkConnection, community, activity, tip },
		orientedShare,
	);

	return {
		data,
		isLoading:
			isActivityLoading &&
			isCommunityLoading &&
			isDealLoading &&
			isNetworkConnectionLoading &&
			isMeetingLoading &&
			isEventLoading &&
			isTipLoading,
		isError:
			isFeedActivityError ||
			isCommunityError ||
			isNetworkConnectionError ||
			isDealError ||
			isMeetingError ||
			isEventError ||
			isTipError,
		community,
	};
};
