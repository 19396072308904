import { useEffect, useParams, useService, useState, useTrackAlgoliaEventEffect } from '@hooks';
import { ActivityIndicator, Gap, ShareArrowIcon } from '@ui-kit';
import { useGetEventQuery } from '@ui-modules/events/hooks/useGetEventQuery';
import { useNavigate } from 'react-router-dom';
import { Page } from '../../components';
import { ROUTES } from '../../routes';
import styles from './EventPage.module.css';
import { clsx } from '@utils';
import EventCancelledToast from '@ui-modules/events/components/EventCancelledToast';
import { setUpBlackthornEventView } from '@ui-modules/events/utils/setUpBlackthornEventView';
import { isItBlackthornUrl } from '@ui-modules/events/utils/isItBlackthornUrl';
import { useInternalShareContext } from '@ui-modules/share/hooks/useInternalShare';

const EventPage = () => {
	const { setInternalShare } = useInternalShareContext();

	const { eventId } = useParams<IEventPageParams>();
	const navigate = useNavigate();
	const analytics = useService('AnalyticsService');

	const {
		data: event,
		isLoading: isEventLoading,
		isSuccess,
	} = useGetEventQuery(eventId, (data, error) => {
		if (!data || error) {
			navigate(ROUTES.calendar(), { replace: true });
		}
	});

	const [isContentLoading, setIsLoading] = useState(true);
	const title = event?.title ? event.title : '...';
	const registrationLink = event?.registrationLink || '';
	const isBlackthornUrl = isItBlackthornUrl(registrationLink);

	useEffect(() => {
		if (event) {
			analytics.trackEvent('event_opened', {
				'#event_name': event?.title,
				'#event_start_datetime': event?.startDate,
				'#event_end_datetime': event?.endDate,
			});
		}
	}, [event]);

	useTrackAlgoliaEventEffect('Event Viewed', { eventSlug: String(event?.id) }, [event?.id]);

	useEffect(() => {
		if (isSuccess && isBlackthornUrl) {
			const app = setUpBlackthornEventView(registrationLink, () => setIsLoading(false));
			return () => app?.destroy?.();
		}
	}, [isSuccess, isBlackthornUrl, registrationLink, eventId]);

	const onShareClickHandler = () => {
		setInternalShare({
			type: 'event',
			id: event?.id,
		});
	};

	return (
		<Page title={title}>
			{isEventLoading ? <ActivityIndicator type="fit" /> : null}
			{isSuccess && registrationLink ? (
				<div className={styles.eventPage__content}>
					{event.isCanceled && (
						<div className={styles.eventPage__toast}>
							<Gap gap={28} />
							<EventCancelledToast canceledReason={event.canceledReason} />
							<Gap gap={24} />
						</div>
					)}
					<header className={styles.eventPage__header}>
						<Gap gap={20} />
						<h4>{title}</h4>
						<button onClick={onShareClickHandler}>
							<ShareArrowIcon height={20} strokeWidth={4} width={20} />
						</button>
					</header>
					<div className={styles.eventPage__frameWrapper}>
						{isContentLoading ? <ActivityIndicator type="fit" /> : null}
						{!isBlackthornUrl ? (
							<iframe
								className={clsx(isContentLoading && styles.eventPage_hiddenElement)}
								frameBorder="0"
								key={registrationLink}
								src={registrationLink}
								title={title}
								onLoad={() => setIsLoading(false)}
							/>
						) : (
							<div
								className={clsx(
									'events-container',
									styles.eventPage__eventContent,
									isContentLoading && styles.eventPage_hiddenElement,
								)}
								key={registrationLink} // 'key' helps to rerender event on switch (fix for T21C-3244) [@dmitriy.nikolenko]
							/>
						)}
					</div>
				</div>
			) : null}
		</Page>
	);
};

interface IEventPageParams extends Record<string, string | undefined> {
	eventId?: string;
}

export default EventPage;
