import { Button, ExternalLinkIcon, Gap } from '@ui-kit';
import { DownloadMobileAppOverlay } from '../DownloadMobileAppOverlay';
import { useTranslation, useEffect, useShowPageInMobile } from '@hooks';
import { APP_STORE_LINK, GOOGLE_PLAY_LINK, MOBILE_APP_URL_SCHEMA, REDIRECT_TO_PARAM_NAME, ROUTES } from '@constants';
import type { PropsWithChildren } from 'react';

const isMacOs = /(macintosh|macintel|macppc|mac68k|macos)/i.test(navigator.userAgent);

const isIOS = /(iPad|iPhone|iPod)/i.test(navigator.userAgent);
const isAndroid = /Android/i.test(navigator.userAgent);
const hasMobiInUserAgent = /Mobi/i.test(navigator.userAgent); // see https://developer.mozilla.org/en-US/docs/Web/HTTP/Browser_detection_using_the_user_agent#mobile_tablet_or_desktop

const isTouchableDevice = 'ontouchstart' in window || navigator.maxTouchPoints > 0;
const isSmallScreenDevice = Math.min(window.screen.width, window.screen.height) < 768;

const isSurelyMobile = isIOS || isAndroid || hasMobiInUserAgent;
const isMaybeMobile = isTouchableDevice && isSmallScreenDevice;

/** The DownloadMobileAppOverlay showed when a user uses mobile browser. */
const MobileScreenWrapper = ({ children }: PropsWithChildren) => {
	const { t } = useTranslation();

	const { showMobilePage } = useShowPageInMobile();
	const isAppBlocked = isSurelyMobile || isMaybeMobile;

	useEffect(() => {
		if (!isMacOs) document.body.classList.add('custom-scrollbar'); // uses to customize scrollbar through styles/scrollbar.css [@dmitriy.nikolenko]
	}, [isMacOs]);

	const openMobileAppOrStore = () => {
		const targetAppSchemaLink = MOBILE_APP_URL_SCHEMA + window.location.pathname;
		const targetWebSchemaLink =
			new URLSearchParams(window.location.search).get(REDIRECT_TO_PARAM_NAME) ?? ROUTES.home();
		const targetLink = isSurelyMobile ? targetAppSchemaLink : targetWebSchemaLink;

		setTimeout(function () {
			if (window.location.href !== targetAppSchemaLink && isSurelyMobile) {
				const maybeStoreLink = isAndroid ? GOOGLE_PLAY_LINK : isIOS ? APP_STORE_LINK : null;
				if (maybeStoreLink) {
					window.location.href = maybeStoreLink;
				}
			}
		}, 1000);
		window.location.href = targetLink;
	};

	if (!showMobilePage && isAppBlocked)
		return (
			<DownloadMobileAppOverlay
				subtitle={t("Don't have T21 Connect installed? Use the links below to download T21 Connect")}
				title={t('Opening "T21 Connect"')}
			>
				<Gap gap={16} />
				<Button
					icon={<ExternalLinkIcon height={14} width={16} />}
					iconPosition="right"
					title={t('Launch The "T21 Connect" App')}
					onClick={openMobileAppOrStore}
				/>
				<Gap gap={8} />
				{window.location.host === 'dev.connect.tiger21.com' || window.location.host.startsWith('localhost') ? (
					<p>
						{JSON.stringify(
							{
								userAgent: navigator.userAgent,
								isIOS,
								isAndroid,
								isTouchableDevice,
								isSmallScreenDevice,
								hasMobiInUserAgent,
								isSurelyMobile,
								isMaybeMobile,
							},
							null,
							2,
						)}
					</p>
				) : null}
			</DownloadMobileAppOverlay>
		);
	return <>{children}</>;
};

export default MobileScreenWrapper;
