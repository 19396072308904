import type { ReactNode } from 'react';
import React, { memo, useEffect } from 'react';
import styles from './ShareableCard.module.css';
import { ActivityIndicator, BlockLink, DealsIcon, LampIconSolid, Modal, RightArrowIcon } from '@ui-kit';
import { useSharedContent } from '@ui-modules/share/hooks/useSharedContent';
import { clsx, InternalShareEntity } from '@utils';
import { useMe, useTranslation } from '@hooks';

import ShareCardContentIcon from './ShareCardContentIcon';
import ShareCardSourceDisplay from './ShareCardSourceDisplay';
import ShareCardCloseButton from './ShareCardCloseButton';
import RestrictedShareCard from '@ui-modules/share/components/ShareableCard/RestrictedShareCard';
import { useState } from 'reinspect';
import { useNetworkMutations } from '@ui-modules/networks/hooks/useNetworkMutations';

const ShareableCard = ({
	clearInternalShare,
	containerClassName,
	sharedContent,
	onClick,
	link,
}: IShareableCardProps) => {
	const { user } = useMe();
	const { t } = useTranslation();

	const internalShareEntity = new InternalShareEntity();

	const { joinNetwork, isLoading: isJoiningNetwork } = useNetworkMutations();
	const { data, isLoading, isError, community } = useSharedContent(sharedContent);

	const [showDialog, setShowDialog] = useState(false);

	useEffect(() => {
		if (isError) {
			clearInternalShare?.();
		}
	}, [isError]);

	const isTipsRestricted = internalShareEntity.isTipRestricted(sharedContent, user);
	const isInvestRestricted = internalShareEntity.isDealRestricted(sharedContent, user);
	const isDiscussionHidden = internalShareEntity.isDiscussionHidden(community);

	const isRestricted = isTipsRestricted || isInvestRestricted;
	// If shared content is a tip and the user doesn't have tips enabled,
	// render a card with a beta message instead of the normal content.
	if (isTipsRestricted) {
		return (
			<RestrictedShareCard
				icon={<LampIconSolid fill="var(--colors-primaryDarkGray)" height={34} width={34} />}
				title={t('Tips are in beta and will be rolled out gradually to all Members.')}
			/>
		);
	}

	// If shared content is a deal and the user doesn't have invest feature enabled
	if (isInvestRestricted) {
		return (
			<RestrictedShareCard
				icon={<DealsIcon fill="var(--colors-primaryDarkGray)" height={34} width={34} />}
				title={t('Invest access is required')}
			/>
		);
	}

	if (isDiscussionHidden) {
		return <RestrictedShareCard title={t('This post is unavailable')} />;
	}

	const onShareableCardClick = () => {
		if (community && !('membership' in community) && sharedContent.includes('discussion')) {
			setShowDialog(true);
			return;
		}
		onClick?.();
	};

	// If there's no data (and we're not loading), don't render anything.
	if (!data && !isLoading) return null;

	const isEvent = data?.compactEvent?.startDate || data?.compactEvent?.endDate;
	const isTip = data?.type === 'tip';

	return (
		<>
			<Wrapper className={clsx(isRestricted && styles.restrictedClassName)} link={!isRestricted ? link : undefined}>
				{/* eslint-disable-next-line jsx-a11y/click-events-have-key-events*/}
				<div
					className={clsx(styles.card, (isEvent || isTip) && styles.card_small, containerClassName)}
					onClick={onShareableCardClick}
				>
					<div className={styles.gradientBackground} />
					<div className={clsx(styles.content, (isEvent || isTip) && styles.content_small)}>
						{isLoading ? (
							<div className={styles.loader}>
								<ActivityIndicator />
							</div>
						) : (
							<>
								<ShareCardContentIcon data={data} />
								<div className={styles.information}>
									<ShareCardSourceDisplay data={data} />
									{data.title && (
										<div className={styles.title} title={data.title}>
											{data.title}
										</div>
									)}
									{data.subtitle && (
										<div className={styles.subtitle} title={data.subtitle}>
											{data.subtitle}
										</div>
									)}
								</div>
							</>
						)}
					</div>
					{clearInternalShare && <ShareCardCloseButton onClick={clearInternalShare} />}
				</div>
			</Wrapper>

			<Modal
				cancelTitle={t('Cancel')}
				confirmIcon={<RightArrowIcon />}
				confirmTitle={t('Join Network')}
				isConfirmLoading={isJoiningNetwork}
				subTitle={t('This post is only visible to members of the {{title}} network.', { title: community?.name })}
				title={t('Join the {{title}} network to view', { title: community?.name })}
				variant="medium"
				visible={showDialog}
				onCancel={() => setShowDialog(false)}
				onConfirm={() => {
					if (community) {
						joinNetwork(community, {
							onSuccess: () => {
								onClick?.();
							},
						});
					}
					setShowDialog(false);
				}}
			/>
		</>
	);
};

const Wrapper = ({ children, link, className }: { children: ReactNode; link?: string; className: string }) => {
	if (link) {
		return (
			<BlockLink className={className} to={link}>
				{children}
			</BlockLink>
		);
	}

	return <div className={className}>{children}</div>;
};

export type TSharedContentType = {
	type: string;
	image?: string;
	source?: string;
	title?: string;
	subtitle?: string;
	compactEvent?: {
		startDate?: string;
		endDate?: string;
	};
};

export interface IShareableCardProps {
	clearInternalShare?: () => void;
	containerClassName?: string;
	link?: string;
	sharedContent: string;
	onClick?: () => void;
}

export default memo(ShareableCard);
