import { MAX_ITEMS_FOR_UPLOAD } from '@constants';
import type { TPostCreation } from '@tiger21-llc/connect-shared/src/schemas/PostCreationForm.schema';
import type { TAttachments, TFeedAttechment } from '@typings';
import { isEqual } from '@utils';
import type { FormikProps } from 'formik';

const getUrlWithoutParams = (url?: string) => String(url).split('?')?.[0];

export const checkInitialAttachmentsItemsExceed = (
	initialFeedAttachments: TFeedAttechment | undefined,
	updatedAttachments: TAttachments[],
	isOnEditMode?: boolean,
) => {
	if (!isOnEditMode) return false;

	const initialAttachments = [
		...(initialFeedAttachments?.files || []),
		...(initialFeedAttachments?.images || []),
		...(initialFeedAttachments?.videos || []),
	].map((url: string) => getUrlWithoutParams(url));
	if (initialAttachments.length <= MAX_ITEMS_FOR_UPLOAD) return false;

	const newAttachments = updatedAttachments.map((attachment) =>
		getUrlWithoutParams(attachment?.url || attachment?.uri),
	);
	if (newAttachments.length !== initialAttachments.length) return false;

	const attachmentNotChanged = isEqual(initialAttachments, newAttachments);
	return !!attachmentNotChanged;
};

export const checkInitialAttachmentsChanged = (
	initialFeedAttachments: TFeedAttechment | undefined,
	updatedAttachments: TAttachments[],
) => {
	const initialAttachments = [
		...(initialFeedAttachments?.files || []),
		...(initialFeedAttachments?.images || []),
		...(initialFeedAttachments?.videos || []),
	].map((url: string) => getUrlWithoutParams(url));

	const newAttachments = updatedAttachments.map((attachment) =>
		getUrlWithoutParams(attachment?.url || attachment?.uri),
	);
	const isAttachmentsEqual = isEqual(initialAttachments, newAttachments);
	return !isAttachmentsEqual;
};

export const customEditFormDisabled = (
	filesExceedingSize: boolean,
	itemsExceedingCount: boolean,
	isInitialItemsExceedingCount: boolean,
	isAttachmentExist: boolean,
	isAttachmentsChanged: boolean,
	{ initialValues, values, isValid }: FormikProps<TPostCreation>,
) => {
	// Determine if sharedContent has changed
	const isSharedContentChanged = initialValues?.sharedContent !== values.sharedContent;

	const valuesExist = !!values.mind?.trim() || isAttachmentExist || !!values.sharedContent?.trim();
	const valuesChanged =
		initialValues?.mind !== values.mind ||
		initialValues?.title !== values.title ||
		isAttachmentsChanged ||
		isSharedContentChanged;

	const isFormDirty = valuesExist && valuesChanged;

	return (filesExceedingSize || isInitialItemsExceedingCount ? false : itemsExceedingCount) || !isFormDirty || !isValid;
};

export const customDisabled = (
	filesExceedingSize: boolean,
	itemsExceedingCount: boolean,
	isInitialItemsExceedingCount: boolean,
	isDirty: boolean,
	isValid: boolean,
) => {
	return (filesExceedingSize || isInitialItemsExceedingCount ? false : itemsExceedingCount) || !isDirty || !isValid;
};
