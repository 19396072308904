import {
	ActivityIndicator,
	Gap,
	Tabs,
	Button,
	NotificationIcon,
	NotificationOffIcon,
	When,
	VisuallyHidden,
} from '@ui-kit';
import { Page, TabWrapper } from '../../components';
import Feed from '@ui-modules/feed/components/Feed';
import { useNavigate, useParams, useService, useTranslation } from '@hooks';
import { useLearnFeedTabsQuery } from '@ui-modules/feed/hooks/useLearnFeedTabsQuery';
import { clsx } from '@utils';
import { ROUTES } from '@constants';
import styles from './LearnPage.module.css';
import { useCommunityNotifications } from '@ui-modules/networks/hooks/useCommunityNotifications';
import { useCollapsedNotificationsSettings } from '@ui-modules/settings/hooks/useNotificationSettingsVisibility';
import PinnedBanners from '@ui-modules/feed/components/PinnedBanners';
import FeedSearchResultList from '@ui-modules/feed/components/FeedSearchResultList';
import { startTransition } from 'react';
import { useNetworkSearchContext } from '@ui-modules/networks/contexts/NetworkSearchContext';

const LearnPage = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const analytics = useService('AnalyticsService');
	const { addCollapsedNotificationsSettings, removeCollapsedNotificationsSettings } =
		useCollapsedNotificationsSettings();

	const { isSearchActive, searchLearnQuery, setRouteSearchLearnQuery } = useNetworkSearchContext();
	const showSearchResults = isSearchActive || !!searchLearnQuery.length;

	const { data: learnTabs = [], isLoading } = useLearnFeedTabsQuery();
	const {
		alertsOn,
		mutateNotifications,
		isLoadingNotificationsSettings,
		isLoading: alertsMutationLoading,
	} = useCommunityNotifications({ communityId: 'learn' });

	// Tabs.
	const feedName = useParams()?.feedName;
	const currentTabIndex = learnTabs.findIndex((learnTab) => learnTab.feedName === feedName);

	// User actions.
	const handleChangeTab = (index: number) => {
		const nextFeedName = learnTabs[index].feedName;
		navigate(ROUTES.learnTab(nextFeedName));
		analytics.trackEvent('LearnInteractions', { learn_category: nextFeedName });
	};

	const onAlertsToggle = async () => {
		await mutateNotifications({ alertsOn: !alertsOn, notificationId: 'learn_post_created' });
		!alertsOn ? addCollapsedNotificationsSettings('Learn') : removeCollapsedNotificationsSettings('Learn');
		analytics.trackEvent('LearnInteractions', {
			page_name: feedName ?? 'all',
			action_taken: alertsOn ? 'alerts off' : 'alerts on',
		});
	};

	if (isLoading || !learnTabs?.length) return <ActivityIndicator size="medium" type="fit" />;
	return (
		<Page title={t('Learn')}>
			<div className={styles.learnPage}>
				<div className={clsx(styles.learnPage__header)}>
					<h3>{t('Learn')}</h3>
					<Button
						icon={
							alertsOn ? (
								<NotificationIcon className={styles.learnPage__notification_icon} height={14} width={22} />
							) : (
								<NotificationOffIcon className={styles.learnPage__notification_icon} height={20} width={20} />
							)
						}
						loading={isLoadingNotificationsSettings || alertsMutationLoading}
						title={alertsOn ? t('Unmute') : t('Mute')}
						variant="small"
						onClick={onAlertsToggle}
					/>
				</div>

				<Tabs selectedIndex={currentTabIndex} onSelect={handleChangeTab}>
					{learnTabs.map((learnTab) => (
						<Tabs.Panel key={learnTab.title}>
							<TabWrapper>
								<Gap gap={12} />
								<When condition={showSearchResults}>
									<FeedSearchResultList
										communityName=""
										isLearning={true}
										searchQuery={searchLearnQuery}
										streamFeedId={learnTab.slug}
										onSearch={(searchQuery) => {
											startTransition(() => {
												setRouteSearchLearnQuery(searchQuery);
											});
										}}
									/>
								</When>
								<VisuallyHidden hidden={showSearchResults}>
									<PinnedBanners
										predicateToFilter={(banner) =>
											learnTab.isAll
												? banner?.globalFeedName?.includes('L--')
												: banner?.globalFeedName === learnTab.slug
										}
									/>
								</VisuallyHidden>
								<Feed hidden={showSearchResults} isActivitySourceHidden userId={learnTab.slug} />
							</TabWrapper>
						</Tabs.Panel>
					))}
				</Tabs>
			</div>
		</Page>
	);
};

export default LearnPage;
