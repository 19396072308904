import { InternalShareEntity } from '@utils';
import ShareableCard from '@ui-modules/share/components/ShareableCard';

const PostSharePreview = ({ sharedContent }: IPostSharePreviewProps) => {
	const internalShareEntity = new InternalShareEntity();
	if (!sharedContent?.length) {
		return null;
	}

	return (
		<ShareableCard
			link={internalShareEntity.internalShareToRoute(sharedContent?.[0] as string)}
			sharedContent={sharedContent[0]}
		/>
	);
};

export interface IPostSharePreviewProps {
	sharedContent: string[] | undefined;
}

export default PostSharePreview;
